import React from "react"

import Layout from "../components/layout/t1/layout"

const DeleteInfo = () => {
  return (
    <Layout>
      <h1>Delete user information</h1>
      <ul>
        <li>Open the MaxApps application on your mobile phone.</li>
        <li>
          Access the application settings by tapping on the settings icon or by
          tapping on the application menu button and selecting "Settings".
        </li>
        <li>
          Scroll through the settings page until you find the option "Delete my
          data" or "Clear my data".
        </li>
        <li>
          Tap on this option and confirm your choice by answering any
          confirmation prompts presented by the application.
        </li>
        <li>
          Once you have confirmed that you want to delete your data, the data
          associated with your MaxApps account will be removed from the
          application.
        </li>
        <li>
          If you have also connected your MaxApps account to Facebook, you will
          need to remove MaxApps data from your Facebook account by following
          Facebook's instructions for deleting an application.
        </li>
        <li>
          Verify that your data has been deleted by closing and reopening the
          MaxApps application to ensure that all of your previous information
          has been removed.
        </li>
      </ul>
      It is important to note that if MaxApps does not store any personal
      information about the user, it is possible that the data collected by
      Facebook as part of the integration of MaxApps with Facebook may not be
      deleted. In this case, it is recommended that users refer to Facebook's
      instructions for deleting MaxApps data from their Facebook account.
    </Layout>
  )
}

export default DeleteInfo
